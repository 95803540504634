.coupon-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.coupon-modal {
  background: #E3CCAE;
  /*border: 2px dashed white;*/
  text-align: center;
  font-size: 1.5rem;
  margin: 20px;
  padding: .3rem;
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 80;

  font-family: 'Rubik', sans-serif;
}

.coupon-modal-inner {
  
  padding: 2rem;

  background: transparent;
  border: 3px dashed white;
  border-radius: 6px;
}

/*.coupon-modal-status {
  color: gray;
  font-size: 0.9em;
}*/
.coupon-modal-status {
  color: black;  /* Light text color for better contrast */
  font-size: 0.9em;
  background-color: white;  /* A pleasant blue background color */
  padding: 10px 20px;  /* Appropriate padding for button-like appearance */
  border: none;  /* Remove any default border */
  border-radius: 25px;  /* Rounded corners */
  cursor: pointer;  /* Cursor indicates button can be clicked */
  transition: background-color 0.3s ease;  /* Smooth transition for hover effect */
  text-align: center;  /* Ensures text is centered */
  display: inline-block;  /* Allows the button to align nicely in line */
}

.coupon-modal-status:hover {
  color: white;
  background-color: #0056b3;  /* Darker blue when hovered for visual feedback */
}

.coupon-modal-metadata {
  color: gray;
  font-size: 0.7em;
}

.coupon-modal-button {
  /*display: block;*/
  /*width: 100%;*/
  padding: 10px 20px;
  border-radius: 20px;
  border: 0px;
  background-color: white;
  color: black;
  color: rgba(0,0,0,0.5);
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  font-family: 'Rubik', sans-serif;
}