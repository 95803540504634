.coupon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  font-size: 3rem;
  user-select: none;

  width: 80px;
  height: 80px;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);

  background: #E3CCAE;
}

.coupon-inner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 70px;
  height: 70px;

  background: transparent;
  border: 2.5px dashed white;
  border-radius: 5px;
}

.coupon:hover {
  transform: scale(1.5);
  transition: transform 0.3s ease-in-out;
}
