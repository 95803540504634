.main {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.header {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: bold;
  height: 80px;
  color: white;
  background-color: transparent;
  z-index: 100;
  font-family: 'Oleo Script', cursive;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  height: 80px;
  color: #BAD7E9;
  background-color: transparent;
  z-index: 100;
  font-family: 'Rubik', sans-serif;
}
